body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
div#reverse-radio {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
div#reverse-radio .form-check.form-check-reverse {
  padding: 5px 35px 5px 5px;
  background: #f6f6f6;
  margin: 5px 5px 0 0;
  border-radius: 5px;
}
